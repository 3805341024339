import React, { useEffect, useRef, useState } from "react"
import { useLocation } from '@reach/router'
import toast from "react-hot-toast"
import { navigate } from "gatsby"
import SimpleReactValidator from "simple-react-validator"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import APIButton from "../components/addon/button/apiButton"
import Thankyou from "../components/addon/thankYou"

import EVENTS_API from "../api/events"
import USER_API from '../api/user'
import PAYMENT_API from '../api/payment'
import useScript from '../service/useScript'
import { acceptOnlyNumbers, compareDates, generateUniqueId, getCookie } from "../service/helper"
import { EVENTS, BILLDESK_MERCHANT_ID, BILLDESK_SECURITY_ID } from '../service/config'
import UseCustomValidator from "../service/simpleValidator"

const EventsRegistration = (props) => {

    const location = useLocation()
    const [loading, setLoading] = useState(false) 
    const [eventDetails, setEventDetails] = useState(null)
    const formOG = {
        webform_id : "events_registration_form",
        //hhno:"",
        salutation :"",
        first_name : "",
        last_name : "",
        age : "",
        gender : "",
        email : "",
        cell_phone_number : "",
        how_did_you_come_to_know_about_us : "",
        medical_registration_number : "",
        designation : "",
        specialization : "",
        associated_to_hospital_nursing_home_college_private_clinic : "",
        address :"",
        state : "",
        city :"",
        pincode : "",
        event_fees : "",
        event_nid : "",
    }
    const [form, setForm] = useState(formOG)
    const [formType, setFormType] = useState(null)
    const [, forceUpdateForRegister] = useState();
    const [selectedEvent, setSelectedEvent] = useState([])
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [userType, setUserType] = useState(null)
    const [success, setSuccess] = useState(false)
    const { customMobileValidator } = UseCustomValidator()
    const validator = customMobileValidator
    const isUserLoggedIn = getCookie("login_id")

    useScript("/assets/js/custom_select.js"); 
    useScript("/assets/js/login-register.js");
    
    const handleEventChange = (e) => {
        const { name, value } = e.target;
        if(value !== form.event_nid) {
            navigate(`/events-registration?id=${value}`)
        }
    }

    const eventsRegistration = () => {
        EVENTS_API.eventRegistration(form)
        .then(res => {
            if(selectedEvent?.paid_event === '1' && res?.sid?.length) {
                redirectToPayment(res.sid)
            } else {
                setSuccess(true)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const redirectToPayment = async (sid) => {
        let eventTitle = upcomingEvents.filter(i => i.nid === form?.event_nid)
         let json = {
             DepositType:"EVENT",
             voucherType: 'EVENT'
        }
        let response = await PAYMENT_API.convertJsonDataToId(json)
        const formAddress = (form?.address).replace(/[^a-zA-Z0-9 ]/g, '');
        eventTitle = (eventTitle[0].title).replace(/[^a-zA-Z0-9 ]/g, '');
        //let str = `${BILLDESK_MERCHANT_ID}|HNHONLPAY-${generateUniqueId()}|NA|${form?.event_fees+'.00'}|NA|NA|NA|INR|NA|R|${BILLDESK_SECURITY_ID}|NA|NA|F|${form?.email}|${form?.cell_phone_number}|${form?.event_nid}|${sid}|NA|NA|NA|NA`
        let str = `${BILLDESK_MERCHANT_ID}|${response?.ref_no}|NA|${form?.event_fees+'.00'}|NA|NA|NA|INR|NA|R|hindujahsp|NA|NA|F|${eventTitle}|${form?.email}|${form?.cell_phone_number}|${sid}|${formAddress}|${response?.date}|${form?.event_fees+'.00'}|https://admin.hindujahospital.com/api/billdesk/eventscallBackUrl`
        let obj = {
            events: true,
            msg: str,
            callback: EVENTS.EVENT_CALLBACK
        }
        navigate(`/payment`,{ state: obj })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'age' || name === 'cell_phone_number' || name === 'medical_registration_number' || name === 'pincode') {
            let val =  acceptOnlyNumbers(value)
            setForm({
                ...form,
                [name]: val,
            });
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const handleUserType = (e) => {
        const { name } = e?.target
        setUserType(name)
        selectedEvent?.delegates.forEach(el => {
            if(el?.delegate_type === name) {
                setForm({
                    ...form,
                    ['event_fees']: el?.delegate_amount,
                });
            }
        })
    }

    const handleSubmit = () => {
        const result = validator.current.allValid()
        if (result === false) {
            validator.current.showMessages()
            forceUpdateForRegister(3)
        } else {	
            eventsRegistration()
        }
    }

    const getUpcomingEventsUsingType = (type) => {
        EVENTS_API.getUpcomingEvents(type)
        .then(res => {
            if(res?.data?.length) {
                setUpcomingEvents(res.data)
            } else {
                toast.error('No Events Found')
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    const getEventsDetailsById = (id) => {
        EVENTS_API.getEventsDetails(id)
        .then(res => {
            if(res?.type) {
                if(res?.current_date_time && res?.registrationDate) {
                    let result = compareDates(res?.current_date_time, res?.registrationDate)
                    if(result) {
                        getUpcomingEventsUsingType(res.type)
                        setSelectedEvent(res)
                        setFormType(res.type)
                    } else {
                        toast.error('Cannot register for past events')
                        navigate('/events')
                    }
                } else {
                    toast.error('No event Found')
                    navigate('/events')
                }
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const getUserData = (pin, eventId) => {
        USER_API.getUserFromHH(pin)
        .then(res => {
            if(res.Data) {
                let arr = res.Data.filter(el => el?.regType === 'HH')
                let user = arr?.[0]
                setForm({
                    ...form,
                    event_nid : eventId,
                    first_name : user?.firstname,
                    last_name : user?.lastname,
                    age : acceptOnlyNumbers(user?.patage),
                    gender : user?.gender === 'M' ? 'Male' : user?.gender === 'F' ? 'Female' : 'Other',
                    email : user?.email,
                    cell_phone_number : user?.mobileno,
                    address :user?.patAdd1,
                    state : user?.patState,
                    city :user?.patCity,
                    pincode : user?.patPOBOX,
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(location?.search?.length) {
            let eventId = location.search.split("=")[1]
            if(Number(eventId)) {
                getEventsDetailsById(eventId)
                if(getCookie("user") && isUserLoggedIn) {
                    let user = JSON.parse(getCookie("user"))
                    if(user?.pin) {
                        getUserData(user?.pin, eventId)
                    }
                } else {
                    setForm({
                        ...form,
                        ['event_nid']: eventId,
                    });
                }
            } else {
               // some redirect code 
            } 
        } else {
            // some redirect code
        }
    },[location])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [selectedEvent, upcomingEvents])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        '/assets/css/accessibility.css',
                        '/assets/css/common.css',
                        'assets/css/login.css',
                    ]
                }}
                tags={[]}
            />
            <main class="innerpage">
                <section class="breadcrumbs">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul>
                                    <li><a href="javascript:;">Home</a></li>
                                    <li><span>Events Registration</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {success
                    ? <Thankyou 
                        msg={`You have successfully registered for the Event. We have sent the details on your registered email id.`} 
                        onClick={() => navigate('/events')} 
                    />
                    : <section class="section_bg login_section section-py eventMainSec">
                    <div class="container">
                        <h2 class="section-heading">Events Registration</h2>
                        <div class="row no-gutters">
                            <div class="col-lg-12 col-md-12">
                                <div class="white_rounded_box">
                                    <div class="form_wrap">
                                        <div class="form_block pl-0">
                                            {/* Fisrt Row */}
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <div className="form-group select-dd">
                                                        <label>Select Event</label>
                                                        <select onChange={handleEventChange} value={form.event_nid} name="event_nid" id="event_nid" className="form-control custom-select" placeholder={selectedEvent?.title || ''}>
                                                            {upcomingEvents.map((event,i) => {
                                                                return (<option key={i} value={event?.nid}>{event?.title}</option>)
                                                            })}
                                                        </select>
                                                        {validator.current.message('event', form.event_nid, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* HH number Row */}
                                            {/* {selectedEvent?.delegates?.[0]?.delegate_type === null ||  selectedEvent?.delegates?.[0]?.delegate_type === undefined ?
                                            <div className="row">
                                                <div className="col-md-3 col-6">
                                                    <div className={`form-group -animated`}>
                                                        <label>HH Number</label>
                                                        <input onChange={handleInputChange} name="hhno" value={form.hhno} className="form-control" type="text" />
                                                        {validator.current.message('hh no', form.hhno, 'numeric|min:3|num')}
                                                    </div>
                                                </div>
                                            </div>
                                            : null } */}

                                            {/* Second Row */}
                                            <div className="row">
                                                <div className="col-md-2 col-3">
                                                    <div className="form-group select-dd">
                                                        <label>Title <span className="mandatory">*</span></label>
                                                        <select onChange={handleInputChange} value={form.salutation} name="salutation" id="salutation" className="form-control custom-select" placeholder={form?.salutation || 'Select'}>
                                                            <option value="Mr">Mr.</option>
                                                            <option value="Ms">Ms.</option>
                                                            <option value="Miss">Mrs.</option>
                                                            <option value="Dr">Dr.</option>
                                                        </select>
                                                        {validator.current.message('salutation', form.salutation, 'required')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-9">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>First Name <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="first_name" value={form.first_name} className="form-control" type="text" />
                                                        {validator.current.message('FirstName', form.first_name, 'required|alpha')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Last Name <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="last_name" value={form.last_name} className="form-control" type="text" />
                                                        {validator.current.message('LastName', form.last_name, 'required|alpha')}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Email <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="email" value={form.email} className="form-control" type="text" />
                                                        {validator.current.message('Email', form.email, 'required|email')}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Third Row */}
                                            <div className="row">
                                                {/* <div className="col-md-2">
                                                    <div className={`form-group select-dd ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Gender</label>
                                                        <select onChange={handleInputChange} value={form.gender} name="gender" className="form-control custom-select" placeholder={form?.gender || 'Select'}>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                        {validator.current.message('gender', form.gender,'')}
                                                    </div>
                                                </div> */}
                                                {selectedEvent?.type === 'Patient Connect' &&
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Age <span className="mandatory">*</span> </label>
                                                        <input onChange={handleInputChange} name="age" value={form.age} className="form-control" type="text" />
                                                        {validator.current.message('Age', form.age, 'required|min:1|max:2')}
                                                    </div>
                                                </div>}
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Mobile <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="cell_phone_number" value={form.cell_phone_number} className="form-control" type="text" />
                                                        {validator.current.message('cell_phone_number', form.cell_phone_number, 'required|min:10|max:10|validateMobile')}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group select-dd">
                                                        <label>How did you find us?</label>
                                                        <select name="how_did_you_come_to_know_about_us" onChange={handleInputChange} value={form?.how_did_you_come_to_know_about_us} className="form-control custom-select" placeholder={form?.how_did_you_come_to_know_about_us || 'Select'}>
                                                            <option value="Internet Advertisement">Internet Advertisement</option>
                                                            <option value="Social Media">Social Media</option>
                                                            <option value="Word of mouth">Word of mouth</option>
                                                            <option value="Emailer">Emailer</option>
                                                            <option value="Other">Other</option>
                                                            <option value="SMS">SMS</option>
                                                            <option value="Google">Google</option>
                                                            <option value="Newspaper Insert">Newspaper Insert</option>
                                                            <option value="Poster">Poster</option>
                                                            <option value="Flyer">Flyer</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Forth Row */}
                                            {formType === 'Medical Events' 
                                            ? <div className="row">
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated`}>
                                                        <label>Designation <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="designation" value={form.designation} className="form-control" type="text" />
                                                        {validator.current.message('designation', form.designation, 'required')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated`}>
                                                        <label>Specialization <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="specialization" value={form.specialization} className="form-control" type="text" />
                                                        {validator.current.message('Specialization', form.specialization, 'required|alpha_space')}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={`form-group -animated`}>
                                                        <label className="topUpAssH">Associated to Hospital  / Nursing Home / College/ Private Clinic <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="associated_to_hospital_nursing_home_college_private_clinic" value={form.associated_to_hospital_nursing_home_college_private_clinic} className="form-control" type="text" />
                                                        {validator.current.message('AssociatedTo', form.associated_to_hospital_nursing_home_college_private_clinic, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                            {/* Fifth Row */}
                                            {selectedEvent?.delegates?.length && selectedEvent?.delegates?.[0]?.delegate_type
                                            ? <div className="row">
                                                <div class="col-md-6">
                                                    <div class="form-group radio-group">
                                                        <label class="radio-main-label">User Type <span className="mandatory">*</span></label>
                                                        <div class="row">
                                                            {selectedEvent?.delegates?.length 
                                                            ? selectedEvent?.delegates?.map(el => {
                                                                if(el?.delegate_type) {
                                                                    return (
                                                                        <div class="col-md-6">
                                                                            <div class="custom-control custom-radio">
                                                                                <input checked={userType === el?.delegate_type} onChange={handleUserType} id={el?.delegate_type} name={el?.delegate_type} value={el?.delegate_type} type="radio" />
                                                                                <label htmlFor={el?.delegate_type}>{el?.delegate_type}</label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            }) 
                                                            : null}
                                                        </div>
                                                        {selectedEvent?.delegates?.length ? validator.current.message('User Type', form.event_fees, 'required') : null}
                                                    </div>
                                                </div>
                                                {/* {userType === 'Doctor' 
                                                    ? <div className="col-md-3 col-6">
                                                        <div className={`form-group -animated`}>
                                                            <label>Medical Registration Number <span className="mandatory">*</span></label>
                                                            <input onChange={handleInputChange} name="medical_registration_number" value={form.medical_registration_number} className="form-control" type="text" />
                                                            {validator.current.message('medicalRegistrationNumber', form.medical_registration_number, 'required|integer')}
                                                        </div>
                                                    </div>
                                                    : null} */}
                                                {selectedEvent?.paid_event === '1' && selectedEvent?.delegates?.length && userType
                                                    ? <div className="col-md-3">
                                                        <div className={`form-group -animated -active select-disabled`}>
                                                            <label>Event Fees </label>
                                                            <input onChange={handleInputChange} disabled={true} name="event_fees" value={form.event_fees} className="form-control" type="text" />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            : null}
                                            {/* Sixth Row */}
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Address <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="address" value={form.address} className="form-control" type="text" />
                                                        {validator.current.message('address', form.address, 'required')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`form-group select-dd ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>State <span className="mandatory">*</span></label>
                                                        <select onChange={handleInputChange} name="state" className="form-control custom-select" placeholder={form?.state || 'Select'}>
                                                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                            <option value="Assam">Assam</option>
                                                            <option value="Bihar">Bihar</option>
                                                            <option value="Delhi">Delhi</option>
                                                            <option value="Chhattisgarh">Chhattisgarh</option>
                                                            <option value="Goa">Goa</option>
                                                            <option value="Gujarat">Gujarat</option>
                                                            <option value="Haryana">Haryana</option>
                                                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                            <option value="Jharkhand">Jharkhand</option>
                                                            <option value="Karnataka">Karnataka</option>
                                                            <option value="Kerala">Kerala</option>
                                                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                            <option value="Maharashtra">Maharashtra</option>
                                                            <option value="Manipur">Manipur</option>
                                                            <option value="Meghalaya">Meghalaya</option>
                                                            <option value="Mizoram">Mizoram</option>
                                                            <option value="Nagaland">Nagaland</option>
                                                            <option value="Orissa">Orissa</option>
                                                            <option value="Punjab">Punjab</option>
                                                            <option value="Rajasthan">Rajasthan</option>
                                                            <option value="Sikkim">Sikkim</option>
                                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                                            <option value="Tripura">Tripura</option>
                                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                            <option value="Uttarakhand">Uttarakhand</option>
                                                            <option value="West Bengal">West Bengal</option>
                                                            <option value="Andaman and Nicobar Islands (UT)">Andaman and Nicobar Islands (UT)</option>
                                                            <option value="Chandigarh (UT)">Chandigarh (UT)</option>
                                                            <option value="Dadra and Nagar Haveli (UT)">Dadra and Nagar Haveli (UT)</option>
                                                            <option value="Daman and Diu (UT)">Daman and Diu (UT)</option>
                                                            <option value="Lakshadweep (UT)">Lakshadweep (UT)</option>
                                                            <option value="Delhi (UT)">Delhi (UT)</option>
                                                            <option value="Puducherry (UT)">Puducherry (UT)</option>
                                                            <option value="Non-Indian State">Non-Indian State</option>
                                                        </select>
                                                        {validator.current.message('state', form.state, 'required')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>City <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="city" value={form.city} className="form-control" type="text" />
                                                        {validator.current.message('city', form.city, 'required|alpha_space')}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated ${isUserLoggedIn ? '-active select-disabled' : ''}`}>
                                                        <label>Pincode <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="pincode" value={form.pincode} className="form-control" type="text" />
                                                        {validator.current.message('pincode', form.pincode, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Seventh Row */}
                                            {formType === 'Medical Events' 
                                            ? <div className="row">
                                                <div className="col-md-3">
                                                    <div className={`form-group -animated`}>
                                                        <label>Medical Registration Number <span className="mandatory">*</span></label>
                                                        <input onChange={handleInputChange} name="medical_registration_number" value={form.medical_registration_number} className="form-control" type="text" />
                                                        {validator.current.message('medicalRegistrationNumber', form.medical_registration_number, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        </div>
                                        <div class="form_block pl-0">
                                            <APIButton onBtnClick={(e) => handleSubmit(e)} title={`Submit`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </main>
        </Layout>
    )
}

export default EventsRegistration